import { CSSProperties } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { PropsConfig } from '../types';

export type PositionProps = {
  position?: CSSProperties['position'] | Nullable<CSSProperties['position']>[];
  left?: CSSProperties['left'] | Nullable<CSSProperties['left']>[];
  right?: CSSProperties['right'] | Nullable<CSSProperties['right']>[];
  top?: CSSProperties['top'] | Nullable<CSSProperties['top']>[];
  bottom?: CSSProperties['bottom'] | Nullable<CSSProperties['bottom']>[];
  zIndex?: CSSProperties['zIndex'] | Nullable<CSSProperties['zIndex']>[];
};

const getPropsConfigMap = (): PropsConfig<PositionProps> => ({
  position: {
    properties: ['position'],
  },
  left: {
    properties: ['left'],
  },
  right: {
    properties: ['right'],
  },
  top: {
    properties: ['top'],
  },
  bottom: {
    properties: ['bottom'],
  },
  zIndex: {
    properties: ['zIndex'],
  },
});

export const position = createPropsToStylesParser<PositionProps>(getPropsConfigMap);
