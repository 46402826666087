import { BrowserCacheLocation, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { config as appConfig } from '../../config';

const msalConfig: Configuration = {
  auth: {
    authority: appConfig.APP_AUTHORITY,
    clientId: appConfig.APP_CLIENT_ID,
    redirectUri: appConfig.APP_REDIRECT_URI,
    postLogoutRedirectUri: appConfig.APP_POST_LOGOUT_URI,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export class MsalWrapper {
  msal: PublicClientApplication;
  initialized: boolean = false;
  scopes: string[] = [`${appConfig.VSPEC_CLIENT_ID}/.default`];

  constructor(config: Configuration) {
    this.msal = new PublicClientApplication(config);
  }

  public async initialize() {
    await this.msal.initialize();
    this.initialized = true;
  }

  public async getAccessToken() {
    let activeAccount = this.msal.getActiveAccount();
    const accounts = this.msal.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return null;
    }

    activeAccount = activeAccount || accounts[0];
    this.msal.setActiveAccount(activeAccount);

    const request = {
      scopes: [`${appConfig.VSPEC_CLIENT_ID}/.default`],
      account: activeAccount,
    };

    if (!this.initialized) {
      await this.msal.initialize();
    }

    let response;

    try {
      response = await this.msal.acquireTokenSilent(request);
    } catch (e) {
      this.msal.acquireTokenRedirect(request);
    }

    return response?.accessToken || null;
  }
}

export const msalWrapperInstance = new MsalWrapper(msalConfig);
