import { CarSpecFragment, FuelType } from '../../@types/gql';
import { theme } from '../../style/theme';
import { updateSearchParam } from '../../utils';

const getEngineDisplayName = (data?: CarSpecFragment) =>
  (data?.engine?.content?.displayName?.value || '').replace(
    /(\(.*\))|man|aut|laddhybrid|mildhybrid|bensin|diesel|,/gi,
    ''
  );

const getCarDisplayName = (data?: CarSpecFragment) => {
  if (!data) {
    return null;
  }

  const model =
    data?.model?.content?.displayName?.value || getCarKeyProperty('carType', data?.carKey);
  const engine = getEngineDisplayName(data);
  const trimLevel = data?.trim?.content?.displayName?.value || '';

  return [...new Set(`${model} ${engine} ${trimLevel}`.split(' '))].join(' ');
};

const mapToInfoProps = (
  contentItems: Array<{
    displayName?: { value?: string | null } | null;
    featureCode?: string;
    matchingCode?: string;
    packageCode?: string;
  }>
) =>
  contentItems.reduce<IInfoProperty[]>((infoProps, item) => {
    if (item.displayName?.value) {
      const key =
        item.matchingCode ||
        item.packageCode ||
        item.featureCode ||
        item.displayName.value.replace(' ', '-').toLocaleLowerCase();

      infoProps.push({
        key,
        value: item.displayName?.value || '',
      });
    }
    return infoProps;
  }, []);

const getImage = (data: CarSpecFragment, size: ImageSize, bg?: string) => {
  const leftUrl = data?.media?.content?.[0].views?.exterior?.studio?.left?.sizes?.[size]?.url;
  const rightUrl = data?.media?.content?.[0].views?.exterior?.studio?.left?.sizes?.[size]?.url;

  if (size === 'large') {
    if (leftUrl) {
      return updateSearchParam(leftUrl, 'bg', bg || theme.color.bg.secondary.replace('#', ''));
    }
    return data?.media?.content?.[0].views?.exterior?.studio?.right?.sizes?.default?.url;
  }
  if (size === 'small') {
    const url = leftUrl || rightUrl;

    return url
      ? updateSearchParam(url, 'bg', bg || theme.color.ornament.divider.replace('#', ''))
      : null;
  }
  return null;
};

const getCarKeyProperty = (property: keyof GoxCarKey, carKey?: GoxCarKey) => {
  if (!carKey?.[property]) {
    return null;
  }
  return carKey[property].toString();
};

export const mapCarData = (data: CarSpecFragment): ICarSpec => {
  const fuelType = getFuelTypeDisplayName(data);
  const gearboxType = getGearboxType(data);
  const modelYear = getCarKeyProperty('modelYear', data?.carKey);
  const modelFamily =
    data?.modelFamily?.content?.displayName?.value ||
    getCarKeyProperty('carType', data?.carKey) ||
    '';
  const engine = getEngineDisplayName(data);

  return {
    vid: data.vid || undefined,
    displayName: `${getCarDisplayName(data)} ${modelYear}` || '',
    modelYear: parseInt(modelYear || '0', 10),
    modelFamily,
    engine,
    images: {
      thumb: getImage(data, 'small', theme.color.bg.secondary.replace('#', '')),
      thumbGrey: getImage(data, 'small'),
      large: getImage(data, 'large'),
      getImage: (size, color) => getImage(data, size, color),
    },
    basicInfo: [
      {
        label: 'Modell',
        key: 'model',
        value: data?.model?.content?.displayName?.value || 'Okänd',
      },
      {
        label: 'Utrustningsnivå',
        key: 'trim-level',
        value: data?.trim?.content?.displayName?.value || 'Okänd',
      },
      {
        label: 'Motor',
        key: 'engine',
        value: data?.engine?.content?.displayName?.value || 'Okänd',
      },
      {
        label: 'Modellår',
        key: 'model-year',
        value: modelYear || 'Okänd',
      },
      {
        label: 'Färg',
        key: 'color',
        value: data?.colour?.content?.displayName?.value || 'Okänd',
      },
      {
        label: 'Växellåda/Drivlina',
        key: 'driveline',
        value: gearboxType,
      },
      {
        label: 'Bränsletyp',
        key: 'fuel-type',
        value: fuelType,
      },
    ],
    features: mapToInfoProps(data?.features?.content || []),
    options: mapToInfoProps(data?.options?.content || []),
    packages: mapToInfoProps(data?.packages?.content || []),
  };
};

const getFuelTypeDisplayName = (data?: CarSpecFragment) => {
  const fuelTypeMap: { [key in FuelType]: string } & { UNKNOWN: string } = {
    DIESEL: 'Diesel',
    PETROL: 'Bensin',
    PETROL_ELECTRIC: 'El/Bensin',
    PURE_ELECTRIC: 'El',
    UNKNOWN: 'Okänd',
  };
  return (
    data?.engine?.content?.fuelType?.formatted ||
    fuelTypeMap[data?.engine?.content?.fuelType?.value || 'UNKNOWN']
  );
};

const getGearboxType = (data?: CarSpecFragment) => {
  if (data?.driveline?.content?.transmissionType?.formatted) {
    return data?.driveline?.content?.transmissionType?.formatted;
  }
  if (data?.gearbox?.content?.displayName?.value) {
    if (data?.gearbox?.content?.displayName.value.match(/aut/i)) {
      return 'Automatisk';
    }
    if (data?.gearbox?.content?.displayName.value.match(/man/i)) {
      return 'Manuell';
    }
  }
  return data?.gearbox?.content?.displayName?.value || '';
};
