import React, { useRef, useState, useEffect, memo } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  id: string;
  children: React.ReactNode;
}

const Portal = ({ id, children }: PortalProps) => {
  const el = useRef(document.getElementById(id) || document.createElement('div'));
  const { current } = el;

  const [dynamic] = useState(!current.parentElement);

  useEffect(() => {
    if (dynamic) {
      current.id = id;
      if (document.body) {
        document.body.appendChild(current);
      }
    }
    return () => {
      if (dynamic && current.parentElement) {
        current.parentElement.removeChild(current);
      }
    };
  }, [id]);

  if (!el.current) {
    return null;
  }

  return ReactDOM.createPortal(children, current);
};

export default memo(Portal);
