// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/font/volvo-novum-medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/font/volvo-novum-medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/font/volvo-novum-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/font/volvo-novum-regular.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Volvo Novum';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
  font-display: swap;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oHAAuI;EACvI,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B;0DACkE;EAClE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Volvo Novum';\n  src: url('../../assets/font/volvo-novum-medium.woff2') format('woff2'), url('../../assets/font/volvo-novum-medium.woff') format('woff');\n  font-display: swap;\n  font-weight: 500;\n}\n\n@font-face {\n  font-family: 'Volvo Novum';\n  src: url('../../assets/font/volvo-novum-regular.woff2') format('woff2'),\n    url('../../assets/font/volvo-novum-regular.woff') format('woff');\n  font-display: swap;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
