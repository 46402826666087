import gql from 'graphql-tag';

export const CAR_SPEC = gql`
  fragment CarSpec on VehicleSpecification {
    vid
    carKey
    vin
    deliveryMetadata {
      content {
        deliveryDate
      }
    }
    media {
      content {
        views {
          exterior {
            studio {
              left {
                sizes {
                  large {
                    url
                  }
                  small {
                    url
                  }
                }
              }
              right {
                sizes {
                  default {
                    url
                  }
                  small {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    modelFamily(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    model(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    engine(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
        engineType {
          value
          formatted
        }
        fuelType {
          formatted
          value
        }
      }
    }
    trim(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    colour(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    gearbox(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    driveline(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        driveType {
          formatted
        }
        transmissionType {
          formatted
        }
      }
    }
    upholstery(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        upholsteryCode
        upholsteryMaterial {
          formatted
        }
      }
    }
    features(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        displayName {
          value
        }
      }
    }
    options(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        matchingCode
        displayName {
          value
        }
      }
    }
    packages(delegationStrategy: MERGE_SOURCES, sequential: false) {
      content {
        packageCode
        displayName {
          value
        }
      }
    }
  }
`;

const SIMILAR_CAR = gql`
  fragment SimilarCar on SimilarCar {
    vid
    findBySimilarity
    classifiedPrice
    modelYear
    odometerStatusKm
    classifiedPublishedDate
    retailerName
    meta {
      displayName
      fetchedDate
      thumbnailImage
    }
  }
`;

const SOLD_CAR = gql`
  fragment SimilarCarSold on SimilarCar {
    ...SimilarCar
    salesInformation {
      daysBeforeSale
      salesDate
    }
  }
`;

const DATA_POINT = gql`
  fragment DataPoint on AnalyticsDatapoint {
    label
    value
    warning
    memberCount
  }
`;

export const MARKET_INSIGHTS = gql`
  ${SIMILAR_CAR}
  ${SOLD_CAR}
  ${DATA_POINT}

  fragment MarketInsights on VehicleSpecification {
    similarCarsCurrentlyPublished(
      coverageStrategy: RESTRICTIVE
      coverageTreshold: 12
      resultsLimit: 12
    ) {
      content {
        coverage
        likenessLevel
        items {
          ...SimilarCar
        }
      }
    }
    similarCarsRecentlySold(coverageTreshold: 33, resultsLimit: 33) {
      content {
        coverage
        likenessLevel
        items {
          ...SimilarCarSold
        }
      }
    }
    classifiedPricePerSalesQuarter: temporalAnalytics(
      queryType: CLASSIFIED_PRICE_PER_SALES_QUARTER
      findByType: PNO3MyE
      coverageStrategy: PERMISSIVE
      memberCoverageTreshold: 10
      windowGravity: LATEST
      windowSize: 8
    ) {
      content {
        likenessLevel
        likenessMatch
        valueUnit
        windowedDataset {
          coverage
          datapoints {
            ...DataPoint
          }
        }
      }
    }
    turnoverRatePerMonth: temporalAnalytics(
      queryType: TURNOVER_RATE_PER_SALES_MONTH
      findByType: PNO3MyE
      coverageStrategy: PERMISSIVE
      memberCoverageTreshold: 5
      windowSize: 12
    ) {
      content {
        likenessLevel
        likenessMatch
        valueUnit
        windowedDataset {
          coverage
          datapoints {
            ...DataPoint
          }
        }
      }
    }
  }
`;

export const VALUATION = gql`
  fragment Valuation on VehicleSpecification {
    vid
    vin
    deliveryMetadata {
      content {
        deliveryDate
      }
    }
    inference(
      contexts: [
        {
          perKeyProperties: [
            [
              { key: "noOfKilometers", value: $noOfKilometers }
              { key: "noOfMonths", value: $extraMonthsAhead, mergeStrategy: Add }
            ]
          ]
        }
      ]
    ) {
      id
      model
      outputs {
        name
        value
      }
      inputs {
        name
        value
      }
      reliability {
        isReliable
        resultBreakdown {
          name
          status
        }
        status
      }
    }
  }
`;
