import { createContext } from 'react';
import { ICarSpecContext } from './types';

export default createContext<ICarSpecContext>({
  carSpec: null,
  valuation: null,
  valuationInput: null,
  loading: false,
  loadingValuation: false,
});
