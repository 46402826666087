import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './features/App';
import { config } from './config';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENVIRONMENT,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

window.addEventListener('load', () => renderApp());

// Opt-in to Webpack hot module replacement
if (module.hot) {
  module.hot.accept('./features/App', () => {
    renderApp();
  });
}
