import React, { ReactNode, useContext, useMemo, useRef } from 'react';
import { useQuery } from 'urql';
import { useParams } from 'react-router-dom';
import { MarketInsightsQuery, MarketInsightsQueryVariables } from '../../@types/gql';
import { marketInsightsQuery } from './gql/queries';
import MarketInsightsContext from './context';
import { mapMarketInsights } from './utils';
import { RouteParams } from '../../routes';
import { IMarketInsightsContext } from './types';
import { useCarSpecContext } from '../CarSpecProvider';

interface IProps {
  children: ReactNode;
}

export const MarketInsightsProvider = ({ children }: IProps) => {
  const { licensePlate } = useParams<RouteParams>();
  const { carSpec } = useCarSpecContext();

  const currentMarketInsights = useRef<IMarketInsights | null>(null);

  const [{ data, error, fetching: loading }] = useQuery<
    MarketInsightsQuery,
    MarketInsightsQueryVariables
  >({
    query: marketInsightsQuery,
    variables: { licensePlate: licensePlate! },
    pause: !licensePlate,
  });

  const marketInsightsData = data?.car?.[0];

  const marketInsights = useMemo(() => {
    if (!marketInsightsData?.similarCarsCurrentlyPublished) {
      return currentMarketInsights.current;
    }

    currentMarketInsights.current = {
      ...currentMarketInsights.current,
      ...mapMarketInsights(marketInsightsData, carSpec),
    };

    return currentMarketInsights.current;
  }, [carSpec, marketInsightsData, currentMarketInsights.current]);

  const contextValue: IMarketInsightsContext = {
    marketInsights,
    loading,
    error: error?.message,
  };

  return (
    <MarketInsightsContext.Provider value={contextValue}>{children}</MarketInsightsContext.Provider>
  );
};

export const useMarketInsightsContext = () => {
  const context = useContext(MarketInsightsContext);
  if (context === undefined) {
    throw new Error('useMarketInsightsContext can only be used inside MarketInsightsProvider');
  }
  return context;
};
