import React, { useEffect, useRef, useState } from 'react';
import { Link } from '../../components/Link';

interface IProps {
  addressParts: string[];
  inverted?: boolean;
}

const MailtoLink = ({ addressParts, ...linkProps }: IProps) => {
  const [user, ...domainParts] = addressParts;
  const [value, setValue] = useState<string>();
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setValue(`${user}@${domainParts.join('.')}`);
    }, 500);

    return () => timer.current && clearTimeout(timer.current);
  }, [addressParts]);

  if (!value) {
    return null;
  }

  return (
    <Link {...linkProps} href={`mailto:${value}`}>
      {value}
    </Link>
  );
};

export default MailtoLink;
