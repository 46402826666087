import React from 'react';
import { Navigate } from 'react-router-dom';

interface IProps {
  children: JSX.Element;
  isAuthenticated?: boolean;
}

const PrivateRoute = ({ children, isAuthenticated }: IProps) => {
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
