import { fetchExchange, Client, CombinedError, AnyVariables, Operation, mapExchange } from 'urql';
import jwtDecode from 'jwt-decode';
import { AuthUtilities, authExchange } from '@urql/exchange-auth';
import { config } from '../../config';
import { MsalWrapper, msalWrapperInstance } from '../Auth';
import * as Sentry from '@sentry/react';

class MsalGqlClient extends Client {
  private token: string | null = null;
  private msalWrapper: MsalWrapper;

  constructor(msalWrapper: MsalWrapper) {
    super({
      url: config.VSPEC_GQL_URI,
      exchanges: [
        mapExchange({
          onError(error) {
            if (error.graphQLErrors.length) {
              error.graphQLErrors.forEach((graphQLError) => {
                Sentry.captureException(graphQLError);
              });
            }

            if (error.networkError) {
              Sentry.captureException(error.networkError);
            }
          },
        }),
        authExchange(async (utils) => {
          this.token = await this.msalWrapper.getAccessToken();

          return {
            addAuthToOperation: this.createAddAuthToOperationFunction(utils).bind(this),
            didAuthError: this.didAuthError.bind(this),
            willAuthError: this.willAuthError.bind(this),
            refreshAuth: this.refreshAuth.bind(this),
          };
        }),
        fetchExchange,
      ],
    });

    this.msalWrapper = msalWrapper;
  }

  createAddAuthToOperationFunction(utils: AuthUtilities) {
    return (operation: Operation<any, AnyVariables>) => {
      if (!this.token) {
        return operation;
      }

      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${this.token}`,
      });
    };
  }

  didAuthError(error: CombinedError) {
    return error.response.status === 401;
  }

  willAuthError() {
    if (!this.token) {
      return true;
    }

    const decodedToken = jwtDecode<IJWTToken>(this.token);

    if (decodedToken.exp < Date.now() / 1000) {
      return true;
    }

    return false;
  }

  async refreshAuth() {
    this.token = await this.msalWrapper.getAccessToken();
  }
}

export default new MsalGqlClient(msalWrapperInstance);
