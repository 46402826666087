declare const APP_AUTHORITY: string;
declare const APP_CLIENT_ID: string;
declare const APP_REDIRECT_URI: string;
declare const APP_POST_LOGOUT_URI: string;
declare const FIREBASE_CONFIG: string;
declare const VSPEC_GQL_URI: string;
declare const VSPEC_CLIENT_ID: string;
declare const BASE_PATH: string;
declare const FEATURE_FUTURE_VALUATION: string;
declare const FEATURE_HIDE_INELIGIBLE_VALUATIONS: string;
declare const FEATURE_VALUATION_REF: string;
declare const FEATURE_RELIABILITY_ASSESSMENT: string;
declare const FEATURE_RELIABILITY_ASSESSMENT_DETAILS: string;
declare const SENTRY_DSN: string;
declare const ENVIRONMENT: string;

export const config: IConfig = {
  APP_AUTHORITY,
  APP_CLIENT_ID,
  APP_REDIRECT_URI,
  APP_POST_LOGOUT_URI,
  BASE_PATH,
  FEATURE_FUTURE_VALUATION: JSON.parse(FEATURE_FUTURE_VALUATION),
  FEATURE_HIDE_INELIGIBLE_VALUATIONS: JSON.parse(FEATURE_HIDE_INELIGIBLE_VALUATIONS),
  FEATURE_VALUATION_REF: JSON.parse(FEATURE_VALUATION_REF),
  FEATURE_RELIABILITY_ASSESSMENT: JSON.parse(FEATURE_RELIABILITY_ASSESSMENT),
  FEATURE_RELIABILITY_ASSESSMENT_DETAILS: JSON.parse(FEATURE_RELIABILITY_ASSESSMENT_DETAILS),
  FIREBASE_CONFIG: JSON.parse(FIREBASE_CONFIG),
  VSPEC_GQL_URI: VSPEC_GQL_URI ?? '/graphql',
  VSPEC_CLIENT_ID,
  SENTRY_DSN,
  ENVIRONMENT,
};
