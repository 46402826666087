import { theme } from './theme';

const { baseUnit } = theme.size;

export const size = (multipliers?: number[] | number) => {
  if (!multipliers) {
    return '';
  }

  if (!Array.isArray(multipliers)) {
    return `${baseUnit * multipliers}px`;
  }

  const valueList: number[] = [];

  multipliers.forEach((item) => {
    valueList.push(item * baseUnit);
  });

  return `${valueList.join('px ')}px`;
};
