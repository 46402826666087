import { DefaultTheme } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { PropsConfig, ThemeStringPaths } from '../types';

type ColorPropValue = ThemeStringPaths<'color'>;

export type ColorProps = {
  bg?: ColorPropValue | Nullable<ColorPropValue>[];
  fg?: ColorPropValue | Nullable<ColorPropValue>[];
};

const getPropsConfigMap = (theme?: DefaultTheme): PropsConfig<ColorProps> => ({
  bg: {
    properties: ['backgroundColor'],
    scale: theme?.color,
  },
  fg: {
    properties: ['color'],
    scale: theme?.color,
  },
});

export const color = createPropsToStylesParser<ColorProps>(getPropsConfigMap);
