import React from 'react';

import { Wrapper, Spinner } from './wrapper';
import { SrOnly } from '../SrOnly';
import { IBoxProps } from '../Box';

interface IProps extends IBoxProps {
  size?: number;
}

const Loader = ({ size, ...boxProps }: IProps) => (
  <Wrapper {...boxProps}>
    <Spinner size={size} aria-hidden="true" />
    <SrOnly>Loading...</SrOnly>
  </Wrapper>
);

export default Loader;
