import styled from 'styled-components';

interface IProps {
  inverted?: boolean;
}

export const Link = styled.a<IProps>`
  &:link,
  &:visited {
    color: ${({ theme, inverted }) => (inverted ? theme.color.fg.primary : theme.color.fg.action)};
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme, inverted }) => (inverted ? theme.color.fg.action : theme.color.fg.primary)};
  }
`;
