import React from 'react';
import { Box } from '../../components/Box';
import { Text } from '../../components/Text';
import MailtoLink from '../MailtoLink';

const Footer = () => (
  <Box px={3} py={2}>
    <Text fontSize="micro" textAlign="center">
      <Text fg="fg.secondary">Ett pilotprojekt av Volvo Cars Sverige</Text>
      <MailtoLink inverted addressParts={['valuation.se', 'volvocars', 'com']} />
    </Text>
  </Box>
);

export default Footer;
