import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';

import '../../assets/css/fonts.css';
import { GlobalStyle } from '../../components/GlobalStyle';
import { theme } from '../../style/theme';

import { AnalyticsProvider } from '../Analytics';
import Routes from '../../routes';
import { PortalElement, PortalNamespace } from '../../components/Portal';
import { msalWrapperInstance } from '../../features/Auth';
import { config } from '../../config';
import { GraphQLClientProvider } from '../GraphQLClient';

const App = () => {
  return (
    <MsalProvider instance={msalWrapperInstance.msal}>
      <AnalyticsProvider firebaseConfig={config.FIREBASE_CONFIG}>
        <BrowserRouter basename={config.BASE_PATH}>
          <GraphQLClientProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Routes />
              <PortalElement id={PortalNamespace.DefaultPortal} />
            </ThemeProvider>
          </GraphQLClientProvider>
        </BrowserRouter>
      </AnalyticsProvider>
    </MsalProvider>
  );
};

export default App;
