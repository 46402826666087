import { CSSProperties } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { PropsConfig } from '../types';

const BASE_UNIT = 8;

type SpacePropValue = number | CSSProperties['margin'] | CSSProperties['padding'];

interface IPaddingProps {
  p?: SpacePropValue | Nullable<SpacePropValue>[];
  pt?: SpacePropValue | Nullable<SpacePropValue>[];
  pr?: SpacePropValue | Nullable<SpacePropValue>[];
  pb?: SpacePropValue | Nullable<SpacePropValue>[];
  pl?: SpacePropValue | Nullable<SpacePropValue>[];
  px?: SpacePropValue | Nullable<SpacePropValue>[];
  py?: SpacePropValue | Nullable<SpacePropValue>[];
}

interface IMarginProps {
  m?: SpacePropValue | Nullable<SpacePropValue>[];
  mt?: SpacePropValue | Nullable<SpacePropValue>[];
  mr?: SpacePropValue | Nullable<SpacePropValue>[];
  mb?: SpacePropValue | Nullable<SpacePropValue>[];
  ml?: SpacePropValue | Nullable<SpacePropValue>[];
  mx?: SpacePropValue | Nullable<SpacePropValue>[];
  my?: SpacePropValue | Nullable<SpacePropValue>[];
}

export type SpaceProps = IPaddingProps & IMarginProps;

const transform = (propValue?: SpacePropValue) => {
  if (!propValue && propValue !== 0) {
    return '';
  }
  return typeof propValue === 'number' ? `${propValue * BASE_UNIT}px` : propValue;
};

const getPropsConfig = (): PropsConfig<SpaceProps> => ({
  p: { properties: ['padding'], transform },
  pt: { properties: ['paddingTop'], transform },
  pr: { properties: ['paddingRight'], transform },
  pb: { properties: ['paddingBottom'], transform },
  pl: { properties: ['paddingLeft'], transform },
  px: { properties: ['paddingLeft', 'paddingRight'], transform },
  py: { properties: ['paddingTop', 'paddingBottom'], transform },
  m: { properties: ['margin'], transform },
  mt: { properties: ['marginTop'], transform },
  mr: { properties: ['marginRight'], transform },
  mb: { properties: ['marginBottom'], transform },
  ml: { properties: ['marginLeft'], transform },
  mx: { properties: ['marginLeft', 'marginRight'], transform },
  my: { properties: ['marginTop', 'marginBottom'], transform },
});

export const space = createPropsToStylesParser<SpaceProps>(getPropsConfig);
