import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { initializeAnalytics, Analytics as FirebaseAnalytics, logEvent } from 'firebase/analytics';
import { IPageViewEventProps, TrackFunction } from './types';

interface IAnalyticsContext {
  track: TrackFunction;
  page: (pageCategory?: string, properties?: IPageViewEventProps) => void;
}

const AnalyticsContext = createContext<IAnalyticsContext | null>(null);

interface IProps {
  firebaseConfig: FirebaseOptions | null;
  children: ReactNode;
}

export const AnalyticsProvider = ({ children, firebaseConfig }: IProps) => {
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<FirebaseAnalytics | null>(null);

  useEffect(() => {
    if (firebaseConfig) {
      const firebaseApp = initializeApp(firebaseConfig);
      setFirebaseAnalytics(initializeAnalytics(firebaseApp));
    }
  }, [firebaseConfig]);

  const page: IAnalyticsContext['page'] = useCallback(
    (_, properties) => {
      if (firebaseAnalytics) {
        logEvent(firebaseAnalytics, 'page_view', {
          page_title: document.title,
          page_path: window.location.pathname,
          page_location: window.location.href,
          ...properties,
        });
      }
    },
    [firebaseAnalytics]
  );

  const track: IAnalyticsContext['track'] = useCallback(
    (event, properties) => {
      if (firebaseAnalytics) {
        logEvent(firebaseAnalytics, event as string, properties);
      }
    },
    [firebaseAnalytics]
  );

  const context = {
    page,
    track,
  };

  return <AnalyticsContext.Provider value={context}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error('useAnalytics can only be used inside AnalyticsProvider');
  }
  return context;
};
