import { styled } from '../../style/mixins/core/styled-wrapper';
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from '../../style/mixins';

export interface IBoxProps
  extends BorderProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    FlexboxProps,
    PositionProps {}

export const Box = styled('div')<IBoxProps>`
  ${flexbox}
  ${border}
  ${color}
  ${space}
  ${layout}
  ${position}
`;
