import React, { ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'urql';
import { useParams } from 'react-router-dom';
import { CarSpecQuery, CarSpecQueryVariables } from '../../@types/gql';
import { carSpecQuery } from './gql/queries';
import CarSpecContext from './context';
import { mapCarData } from './utils';
import { RouteParams } from '../../routes';
import { ICarSpecContext } from './types';
import { useValuationResult } from '../../hooks/useValuationResult';

interface IProps {
  children: ReactNode;
}

export const CarSpecProvider = ({ children }: IProps) => {
  const { licensePlate, odometerStatus: urlOdometerStatus } = useParams<RouteParams>();

  const noOfKilometers = parseInt(urlOdometerStatus || '0', 10) * 10;

  const currentLicensePlate = useRef<string | null>(null);
  const currentCarSpec = useRef<ICarSpec | null>(null);

  const isInitialQuery = Boolean(licensePlate) && currentLicensePlate.current !== licensePlate;

  const [{ data, error, fetching: loading }] = useQuery<CarSpecQuery, CarSpecQueryVariables>({
    query: carSpecQuery,
    variables: {
      licensePlate: licensePlate!,
      noOfKilometers,
      isInitialQuery,
    },
    pause: !licensePlate,
  });

  const carData = data?.car?.[0];
  const valuation = useValuationResult(data);

  useEffect(() => {
    if (carData?.vid && licensePlate) {
      currentLicensePlate.current = licensePlate;
    }
  }, [carData?.vid]);

  const carSpec = useMemo(() => {
    if (!licensePlate || !carData?.carKey) {
      return currentCarSpec.current;
    }

    currentCarSpec.current = {
      ...currentCarSpec.current,
      ...mapCarData(carData),
    };

    return currentCarSpec.current;
  }, [carData, licensePlate, currentCarSpec.current]);

  const contextValue: ICarSpecContext = {
    carSpec,
    valuation,
    valuationInput: {
      licensePlate,
      odometerStatus: noOfKilometers / 10,
    },
    loading: isInitialQuery && loading,
    loadingValuation: !isInitialQuery && loading,
    error,
  };

  return <CarSpecContext.Provider value={contextValue}>{children}</CarSpecContext.Provider>;
};

export const useCarSpecContext = () => {
  const context = useContext(CarSpecContext);
  if (context === undefined) {
    throw new Error('useCarSpecContext can only be used inside CarSpecProvider');
  }
  return context;
};
