import { CSSObject, DefaultTheme, StyledProps } from 'styled-components';
import { CSSMediaQuery, FreeFormScaleValue, OrdinalScale, PropsConfig } from '../types';

export const getMediaQuery = (minWidth: string): CSSMediaQuery =>
  `@media screen and (min-width: ${minWidth})`;

// Cases:
// * Ordinal scale reference (number)
// * Alias to ordinal value (string)
// * Freeform scale reference (string)
export const getScaleValue = (
  scale: OrdinalScale | FreeFormScaleValue,
  key: string | number
): string | number => {
  const keys = (typeof key === 'string' ? key.split('.') : [key]) as (string | number)[];
  const value = keys.reduce<any>((o, k) => o[k], scale);

  return value;
};

export const createPropsToStylesParser = <T>(
  getPropsConfigMap: (theme?: DefaultTheme) => PropsConfig<T>
) => {
  let propsConfig: PropsConfig<T> = getPropsConfigMap();

  const parser = (styledProps: StyledProps<T>) => {
    let styles: CSSObject = {};
    const { theme, ...props } = styledProps;

    propsConfig = getPropsConfigMap(theme);

    let key: keyof typeof props;
    for (key in props) {
      const propValue = props[key] as any;

      if ((!propValue && propValue !== 0) || !propsConfig[key]) {
        continue;
      }

      const propConfig = propsConfig[key];

      const transformPropValue = (propValue: any) => {
        if (propConfig.transform) {
          return propConfig.transform(propValue as string & number, theme, styledProps);
        } else if (propConfig.scale) {
          return getScaleValue(propConfig.scale, propValue);
        }
        return propValue;
      };

      // Responsive style
      if (Array.isArray(propValue)) {
        const mediaQueries: (CSSMediaQuery | null)[] = [
          null,
          ...theme.breakpoints.map(getMediaQuery),
        ];

        propValue.slice(0, mediaQueries.length).forEach((breakpointValue, i) => {
          const mediaQuery = mediaQueries[i];
          const propStyles = propConfig.properties.reduce<CSSObject>(
            (allPropStyles, styleProperty) => {
              allPropStyles[styleProperty as string] =
                breakpointValue !== null ? transformPropValue(breakpointValue) : null;
              return allPropStyles;
            },
            {}
          );

          if (mediaQuery) {
            styles[mediaQuery] = {
              ...(styles[mediaQuery] as { [key: string]: string }),
              ...propStyles,
            };
          } else {
            styles = { ...styles, ...propStyles };
          }
        });
      } else {
        // Plain style (no array as prop value)
        propConfig.properties.forEach((property) => {
          if (propValue || propValue === 0) {
            styles[property as string] = transformPropValue(propValue);
          }
        });
      }
    }

    return styles;
  };

  parser.propNames = Object.keys(propsConfig);

  return parser;
};
