const _colors = {
  black00: '#000000',
  black10: '#141414',
  black20: '#2b2b2b',

  gray10: '#fafafa',
  gray20: '#ebebeb',
  gray30: '#d5d5d5',
  gray40: '#707070',

  blue10: '#1c6bba',
  blue20: '#284e80',

  red: '#bf2012',
  green: '#058a30',

  white: '#ffffff',
  transparent: 'transparent',
};

const _brand = {
  primary: _colors.blue20,
};
const _bg = {
  primary: _colors.white,
  secondary: _colors.gray10,
  inverted: _colors.black10,
  transparent: _colors.transparent,
  green: _colors.green,
};
const _fg = {
  primary: _colors.black10,
  secondary: _colors.gray40,
  inverted: _colors.white,
  alert: _colors.red,
  action: _colors.blue10,
};

const fontSizesArray = [
  '0.75rem', // 0 12px
  '0.875rem', // 1 14px
  '1rem', // 2 16px
  '1.25rem', // 3 20px
  '1.5rem', // 4 24px
  '1.75rem', // 5 28px
  '2rem', // 6 32px
  '2.25rem', // 7 36px
  '2.5rem', // 8 40px
  '3rem', // 9 48px
  '3.25rem', // 10 52px
  '3.5rem', // 11 56px
  '4rem', // 12 64px
  '4.5rem', // 13 72px
  '5rem', // 14 80px
  '6rem', // 15 96px
];

const fontSizes = {
  statement1: {
    sm: fontSizesArray[12],
    m: fontSizesArray[14],
    l: fontSizesArray[15],
  },
  statement2: {
    sm: fontSizesArray[11],
    m: fontSizesArray[12],
    l: fontSizesArray[13],
  },
  statement3: {
    sm: fontSizesArray[9],
    m: fontSizesArray[10],
    l: fontSizesArray[11],
  },
  heading1: {
    sm: fontSizesArray[6],
    m: fontSizesArray[7],
    l: fontSizesArray[8],
  },
  heading2: {
    sm: fontSizesArray[4],
    m: fontSizesArray[5],
    l: fontSizesArray[6],
  },
  heading3: {
    sm: fontSizesArray[4],
    m: fontSizesArray[5],
    l: fontSizesArray[6],
  },
  title1: fontSizesArray[4],
  title2: fontSizesArray[3],
  body1: fontSizesArray[2],
  body2: fontSizesArray[1],
  micro: fontSizesArray[0],
};

const _buttonStyles = {
  primary: {
    default: {
      bg: {
        default: _brand.primary,
        hover: _bg.inverted,
      },
      fg: {
        default: _fg.inverted,
        hover: _fg.inverted,
      },
      border: {
        default: _brand.primary,
        hover: _bg.inverted,
      },
    },
    inverted: {
      bg: {
        default: _colors.white,
        hover: _colors.gray20,
      },
      fg: {
        default: _colors.black00,
        hover: _fg.secondary,
      },
      border: {
        default: _colors.white,
        hover: _colors.gray20,
      },
    },
  },
  outline: {
    default: {
      bg: {
        default: _colors.transparent,
        hover: _brand.primary,
      },
      fg: {
        default: _fg.action,
        hover: _fg.inverted,
      },
      border: {
        default: _fg.action,
        hover: _fg.inverted,
      },
    },
    inverted: {
      bg: {
        default: _colors.transparent,
        hover: _colors.white,
      },
      fg: {
        default: _fg.inverted,
        hover: _fg.primary,
      },
      border: {
        default: _fg.inverted,
        hover: _fg.inverted,
      },
    },
  },
  floating: {
    default: {
      bg: {
        default: _colors.black10,
        hover: _colors.black20,
      },
      fg: {
        default: _fg.inverted,
        hover: _fg.inverted,
      },
      border: {
        default: _colors.black10,
        hover: _colors.black20,
      },
    },
    inverted: {
      bg: {
        default: _colors.transparent,
        hover: _colors.white,
      },
      fg: {
        default: _fg.inverted,
        hover: _fg.primary,
      },
      border: {
        default: _fg.inverted,
        hover: _fg.inverted,
      },
    },
  },
};

export const theme = {
  color: {
    brand: _brand,
    bg: _bg,
    fg: _fg,
    ornament: {
      border: _colors.gray30,
      divider: _colors.gray20,
      highlight: _colors.blue10,
      alert: _colors.red,
    },
    button: _buttonStyles,
  },
  fontFamily: 'Volvo Novum, sans-serif',
  fontSizes,
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 500,
  },
  lineHeight: 1.5,
  breakpoints: ['480px', '768px', '1024px'],
  size: {
    baseUnit: 8,
    containerMaxWidth: '1272px',
    containerGutter: '24px',
    contentMaxWidth: '560px',
    inputHeight: '56px',
    inputHeightSmall: '48px',
  },
};

export type BackgroundColors = keyof typeof theme.color.bg;
export type ForegroundColors = keyof typeof theme.color.fg;
export type ButtonTheme = typeof theme.color.button;
export type ButtonStyles = keyof typeof theme.color.button;
export type Theme = typeof theme;
