import { default as styledStyled, StyledComponentPropsWithRef } from 'styled-components';
import { border, color, flexbox, layout, position, space, typography } from '../';

const allPropsNames = [
  ...border.propNames,
  ...color.propNames,
  ...flexbox.propNames,
  ...layout.propNames,
  ...position.propNames,
  ...space.propNames,
  ...typography.propNames,
];

export const styled = <
  C extends keyof JSX.IntrinsicElements | React.ComponentType<any>,
  P extends object
>(
  tag: C
) =>
  styledStyled(tag).withConfig<P>({
    shouldForwardProp: (
      prop,
      defaultValidatorFn: (prop: keyof StyledComponentPropsWithRef<C> | keyof P) => boolean
    ) => !allPropsNames.includes(prop.toString()) && defaultValidatorFn(prop),
  });
