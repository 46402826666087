import { CSSProperties, DefaultTheme } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { FreeFormScaleValue, PropsConfig, ThemeStringPaths } from '../types';

export type TypographyProps = {
  fontSize?: ThemeStringPaths<'fontSizes'> | Nullable<ThemeStringPaths<'fontSizes'>>[];
  fontWeight?: FreeFormScaleValue | FreeFormScaleValue[];
  textAlign?: CSSProperties['textAlign'] | CSSProperties['textAlign'][];
  textTransform?: CSSProperties['textTransform'];
  textDecoration?: CSSProperties['textDecoration'];
};

const getPropsConfigMap = (theme?: DefaultTheme): PropsConfig<TypographyProps> => ({
  fontSize: {
    properties: ['fontSize'],
    scale: theme?.fontSizes,
  },
  fontWeight: {
    properties: ['fontWeight'],
    scale: theme?.fontWeights,
  },
  textAlign: {
    properties: ['textAlign'],
  },
  textTransform: {
    properties: ['textTransform'],
  },
  textDecoration: {
    properties: ['textDecoration'],
  },
});

export const typography = createPropsToStylesParser<TypographyProps>(getPropsConfigMap);
