import styled from 'styled-components';

import { size as sizeHelper } from '../../style/helpers';
import { rotate } from '../../style/keyframes';
import { Box } from '../Box';

interface IProps {
  size?: number;
}

export const Spinner = styled.div<IProps>`
  position: relative;
  display: block;
  margin: 0 auto;
  width: ${({ size }) => sizeHelper(size)};
  height: ${({ size }) => sizeHelper(size)};
  border-radius: 50%;
  border: 2px solid currentColor;
  border-top-color: transparent;
  animation: ${rotate} 600ms linear infinite;

  @media (prefers-reduced-motion) {
    animation-duration: 1500ms;
  }
`;
Spinner.defaultProps = {
  size: 4,
};

export const Wrapper = styled(Box).attrs({
  role: 'status',
})`
  color: currentColor;
`;
