import { useMemo } from 'react';
import {
  CarSpecQuery,
  FutureValuationQuery,
  InferenceReliabilityStatus as ReliabilityStatus,
} from '../@types/gql';
import { config } from '../config';
import { formatNumber } from '../utils';

const getReliabilityMessage = (
  noOfSimilarCarsStatus?: ReliabilityStatus,
  rmseStatus?: ReliabilityStatus
) => {
  if (rmseStatus === ReliabilityStatus.INELIGIBLE) {
    return 'Värderingen för denna bilen kunde ej genomföras på grund av bristande annonsunderlag.';
  }

  if (noOfSimilarCarsStatus === ReliabilityStatus.RELIABLE) {
    return rmseStatus === ReliabilityStatus.UNRELIABLE ? 'Stor spridning i annonserat pris.' : '';
  }

  if (noOfSimilarCarsStatus === ReliabilityStatus.UNRELIABLE) {
    return rmseStatus === ReliabilityStatus.RELIABLE
      ? 'Begränsat antal liknande bilar.'
      : 'Begränsat antal liknande bilar och stor spriding i annonserat pris.';
  }

  if (noOfSimilarCarsStatus === ReliabilityStatus.INELIGIBLE) {
    return rmseStatus === ReliabilityStatus.RELIABLE
      ? 'Begränsat antal liknande bilar.'
      : 'Värderingen för denna bilen kunde ej genomföras på grund av bristande annonsunderlag.';
  }

  return '';
};

export const useValuationResult = (valuationData?: CarSpecQuery | FutureValuationQuery | null) => {
  const valuation = useMemo(() => {
    const inference = valuationData?.car?.[0]?.inference?.[0];
    const inferenceOutputs = inference?.outputs;
    let amount = inferenceOutputs?.find((output) => output.name === 'price')?.value ?? null;

    if (amount === null) {
      return null;
    }

    amount = amount = Math.round(amount / 500) * 500;

    const mae = inferenceOutputs?.find((output) => output.name === 'mae')?.value ?? null;
    const noOfSimilarCars =
      inferenceOutputs?.find((output) => output.name === 'no_of_similar_cars')?.value ?? null;

    const noOfSimilarCarsReliability = inference?.reliability?.resultBreakdown.find(
      (item) => item.name === 'no_of_similar_cars'
    );
    const rmseReliability = inference?.reliability?.resultBreakdown.find(
      (item) => item.name === 'rmse'
    );

    const reliabilityMessage = getReliabilityMessage(
      noOfSimilarCarsReliability?.status ?? undefined,
      rmseReliability?.status ?? undefined
    );

    return {
      id: inference?.id ?? null,
      amount,
      mae,
      noOfSimilarCars,
      ageInMonths: inference?.inputs.find((input) => input.name === 'noOfMonths')?.value ?? 0,
      valuationDisplay: formatNumber(amount),
      modelChoice: inference?.model ?? 'default',
      reliability: {
        status: inference?.reliability?.status,
        message: reliabilityMessage,
      },
      showCopyRefButton: Boolean(inference?.id && config.FEATURE_VALUATION_REF),
    };
  }, [valuationData]);

  return valuation;
};
