import { css } from 'styled-components';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from '../../style/mixins';
import { styled } from '../../style/mixins/core/styled-wrapper';

export interface IText extends ColorProps, SpaceProps, TypographyProps {
  decoration?: 'line-through';
}

export const Text = styled('div')<IText>`
  ${space}
  ${typography}
  ${color}

  ${({ decoration }) =>
    decoration &&
    css`
      text-decoration: ${decoration};
    `}
`;
