import {
  Coverage,
  MarketInsightsFragment,
  SimilarCarFragment,
  SimilarCarSoldFragment,
} from '../../@types/gql';
import { theme } from '../../style/theme';
import { updateSearchParam } from '../../utils';

const mapToSimilarCar = (carItem: SimilarCarFragment): ISimilarCar => ({
  vid: carItem.vid,
  displayName: carItem.meta?.displayName ? `${carItem.meta?.displayName} ${carItem.modelYear}` : '',
  images: {
    cluster: updateSearchParam(
      carItem.meta?.thumbnailImage || '',
      'bg',
      theme.color.ornament.divider.replace('#', '')
    ),
    thumb: updateSearchParam(
      carItem.meta?.thumbnailImage || '',
      'bg',
      theme.color.bg.secondary.replace('#', '')
    ),
  },
  classified: {
    odometerStatus: carItem.odometerStatusKm,
    price: carItem.classifiedPrice,
    publishedDate: new Date(carItem.classifiedPublishedDate).toLocaleString('sv-SE', {
      day: 'numeric',
      month: 'long',
    }),
    dealer: carItem?.retailerName || '',
  },
});

const mapToSoldCar = (carItem: SimilarCarSoldFragment): ISimilarCarSold => ({
  ...mapToSimilarCar(carItem),
  salesInformation: {
    daysBeforeSale: carItem.salesInformation?.daysBeforeSale || null,
    salesDate:
      (carItem.salesInformation?.salesDate &&
        new Date(carItem.salesInformation.salesDate).toLocaleString('sv-SE', {
          day: 'numeric',
          month: 'long',
        })) ||
      '',
  },
});

export const carSpecToSimilarCar = (
  carSpec: ICarSpec,
  odometerStatus?: number,
  valuation?: number
) => ({
  displayName: carSpec?.displayName,
  images: {
    cluster: carSpec.images.thumbGrey || '',
    thumb: carSpec.images.thumb || '',
  },
  classified: {
    odometerStatus: (odometerStatus || 0) * 10,
    price: valuation || 0,
    dealer: '',
    publishedDate: '',
  },
  isValuatedCar: true,
});

const mapSimilarCars = <IN extends SimilarCarFragment, OUT>(
  data: IN[],
  mapFunction: (car: IN) => OUT
): OUT[] =>
  data?.filter((car) => car.findBySimilarity && car.findBySimilarity >= 0.7).map(mapFunction) || [];

export const mapMarketInsights = (
  data: MarketInsightsFragment,
  carSpec: ICarSpec | null
): IMarketInsights => {
  const description = carSpec
    ? `${carSpec?.modelYear} ${carSpec?.modelFamily} ${carSpec?.engine}`
    : '';

  return {
    similarCars: {
      currentlyPublished: mapSimilarCars<SimilarCarFragment, ISimilarCar>(
        data?.similarCarsCurrentlyPublished?.content?.items || [],
        mapToSimilarCar
      ),
      recentlySold: mapSimilarCars<SimilarCarSoldFragment, ISimilarCarSold>(
        data?.similarCarsRecentlySold?.content?.items || [],
        mapToSoldCar
      ),
    },
    statistics: {
      classifiedPricePerSalesQuarter: {
        description,
        coverage:
          data?.classifiedPricePerSalesQuarter?.content?.windowedDataset.coverage ||
          Coverage.INCOMPLETE,
        dataset: [
          ...(data?.classifiedPricePerSalesQuarter?.content?.windowedDataset.datapoints || []),
        ].reverse(),
      },
      turnoverRatePerMonth: {
        description,
        coverage:
          data?.turnoverRatePerMonth?.content?.windowedDataset.coverage || Coverage.INCOMPLETE,
        dataset: [
          ...(data?.turnoverRatePerMonth?.content?.windowedDataset.datapoints || []).map(
            (datapoint) => ({ ...datapoint, value: Math.round(datapoint.value) })
          ),
        ].reverse(),
      },
    },
  };
};
