import gql from 'graphql-tag';
import { CAR_SPEC } from '../../../gql/fragments';

export const carSpecByVidQuery = gql`
  ${CAR_SPEC}

  query CarSpecByVidQuery($vid: [String!]!) {
    specificationByVid(vid: $vid) {
      ...CarSpec
    }
  }
`;
