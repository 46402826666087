import { CSSProperties } from 'styled-components';
import { createPropsToStylesParser } from '../core';
import { PropsConfig } from '../types';

type LayoutPropValue = number | CSSProperties['width'] | CSSProperties['height'];

export type LayoutProps = {
  aspectRatio?: (number | 'auto') | Nullable<number | 'auto'>[];
  display?: CSSProperties['display'] | Nullable<CSSProperties['display']>[];
  h?: LayoutPropValue | Nullable<LayoutPropValue>[];
  maxHeight?: LayoutPropValue | Nullable<LayoutPropValue>[];
  minHeight?: LayoutPropValue | Nullable<LayoutPropValue>[];
  w?: LayoutPropValue | Nullable<LayoutPropValue>[];
  maxWidth?: LayoutPropValue | Nullable<LayoutPropValue>[];
  overflow?: CSSProperties['overflow'] | Nullable<CSSProperties['overflow']>[];
};

const transform = (propValue?: LayoutPropValue) => {
  if (!propValue) {
    return '';
  }

  if (typeof propValue === 'string') {
    return propValue;
  }

  if (propValue > 1) {
    return `${propValue}px`;
  }
  return `${propValue * 100}%`;
};

const getPropsConfig = (): PropsConfig<LayoutProps> => ({
  aspectRatio: {
    properties: ['aspectRatio'],
    transform: (propValue: number) => propValue.toString(),
  },
  display: {
    properties: ['display'],
  },
  h: {
    properties: ['height'],
    transform,
  },
  maxHeight: {
    properties: ['maxHeight'],
    transform,
  },
  minHeight: {
    properties: ['minHeight'],
    transform,
  },
  w: {
    properties: ['width'],
    transform,
  },
  maxWidth: {
    properties: ['maxWidth'],
    transform,
  },
  overflow: {
    properties: ['overflow'],
  },
});

export const layout = createPropsToStylesParser<LayoutProps>(getPropsConfig);
