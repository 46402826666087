export const parseNumber = (value: string) => parseFloat(value.replace(/[,\.\s]/g, ''));

export const formatNumber = (value: number) => Math.round(value).toLocaleString('sv-SE');

export const formatCurrency = (value: number) => `${formatNumber(value)} kr`;

export const daysFormatter = (value: number) => `${value} dagar`;

export const formatDistance = (value: number) => `${formatNumber(value)} mil`;

export const updateSearchParam = (url: string, param: string, paramValue: string): string => {
  const urlObj = new URL(url);
  urlObj.searchParams.set(param, paramValue);
  return urlObj.toString();
};

export const getValuationUrl = ({ licensePlate, odometerStatus }: IValuationInput) =>
  `/valuation/${licensePlate}/${odometerStatus || 0}`;
