import React, { ReactNode } from 'react';
import { Provider } from 'urql';
import client from './client';

interface IProps {
  children: ReactNode;
}

export const GraphQLClientProvider = ({ children }: IProps) => (
  <Provider value={client}>{children}</Provider>
);
