import { useQuery } from 'urql';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CarSpecByVidQuery, CarSpecByVidQueryVariables } from '../../@types/gql';
import { useCarSpecContext } from '../CarSpecProvider';
import { mapCarData } from '../CarSpecProvider/utils';
import { carSpecByVidQuery } from '../SimilarCars/gql/queries';
import { ICarSpecModalContext, ICarSpecModalViewModel } from './types';

interface IProps {
  children: ReactNode;
}

const CarSpecModalContext = createContext<ICarSpecModalContext>({
  car: null,
  loading: false,
  shouldShowModal: false,
  closeModal: () => undefined,
  showCar: () => undefined,
});

export const CarSpecModalProvider = ({ children }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [similarCar, setSimilarCar] = useState<ISimilarCar | null>(null);

  const {
    carSpec: valuatedCar,
    loading: loadingValuatedCar,
    valuation,
    valuationInput,
    loadingValuation,
  } = useCarSpecContext();

  const [{ data: carSpecByVidData, fetching: loadingCarSpecByVid }, loadCarByVid] = useQuery<
    CarSpecByVidQuery,
    CarSpecByVidQueryVariables
  >({
    query: carSpecByVidQuery,
    variables: { vid: similarCar?.vid! },
    pause: true,
  });

  const loading = loadingCarSpecByVid || loadingValuatedCar || loadingValuation;

  useEffect(() => {
    if (similarCar?.vid) {
      loadCarByVid();
    }
  }, [similarCar, loadCarByVid]);

  const showCar = useCallback(
    (car?: ISimilarCar) => {
      let update = null;

      if (car) {
        update = car;
      }
      setSimilarCar(update);
      setShowModal(true);
    },
    [similarCar]
  );

  const car: ICarSpecModalViewModel | null = useMemo(() => {
    if (carSpecByVidData?.specificationByVid?.[0] && similarCar && !loading) {
      return {
        ...mapCarData(carSpecByVidData.specificationByVid[0]),
        odometerStatus: similarCar.classified.odometerStatus / 10,
        valuation: similarCar.classified.price,
        valuationLabel: `Annonserat pris`,
      };
    }
    if (similarCar?.isValuatedCar && valuatedCar && valuation) {
      return {
        ...valuatedCar,
        odometerStatus: valuationInput?.odometerStatus || 0,
        valuation: valuation.amount || 0,
        valuationLabel: 'Uppskattat värde',
      };
    }
    return null;
  }, [carSpecByVidData, valuatedCar, similarCar, loading]);

  const contextValue: ICarSpecModalContext = {
    car,
    loading,
    showCar,
    closeModal: () => setShowModal(false),
    shouldShowModal: showModal,
  };

  return (
    <CarSpecModalContext.Provider value={contextValue}>{children}</CarSpecModalContext.Provider>
  );
};

export const useCarSpecModalContext = () => {
  const context = useContext(CarSpecModalContext);
  if (context === undefined) {
    throw new Error('useCarSpecModalContext can only be used inside CarSpecModalProvider');
  }
  return context;
};
