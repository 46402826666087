import { DefaultTheme, StyledProps } from 'styled-components';
import { createPropsToStylesParser, getScaleValue } from '../core';
import { PropsConfig, ThemeStringPaths } from '../types';

type BorderPropValue = number | null;

export type BorderProps = {
  b?: BorderPropValue | BorderPropValue[];
  bt?: BorderPropValue | BorderPropValue[];
  br?: BorderPropValue | BorderPropValue[];
  bb?: BorderPropValue | BorderPropValue[];
  bl?: BorderPropValue | BorderPropValue[];
  borderRadius?: number | (number | null)[];
  borderColor?: ThemeStringPaths<'color'> | false | undefined | null;
};

const transform = (
  propValue: BorderPropValue,
  theme: DefaultTheme,
  props: StyledProps<BorderProps>
) => {
  if (!propValue && propValue !== 0) {
    return '';
  }
  const color = props.borderColor
    ? getScaleValue(theme.color, props.borderColor)
    : theme.color.ornament.divider;
  return `solid ${propValue}px ${color}`;
};

const transformBorderRadius = (propValue: BorderPropValue) => `${propValue}px`;

const getPropsConfig = (theme?: DefaultTheme): PropsConfig<BorderProps> => ({
  b: { properties: ['border'], transform },
  bt: { properties: ['borderTop'], transform },
  br: { properties: ['borderRight'], transform },
  bb: { properties: ['borderBottom'], transform },
  bl: { properties: ['borderLeft'], transform },
  borderRadius: { properties: ['borderRadius'], transform: transformBorderRadius },
  borderColor: {
    properties: ['borderColor'],
    scale: theme?.color,
  },
});

export const border = createPropsToStylesParser<BorderProps>(getPropsConfig);
