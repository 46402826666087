import React from 'react';

import { Wrapper } from './wrapper';

export interface PortalElementProps {
  id: string;
}

const PortalElement = ({ id }: PortalElementProps) => <Wrapper id={id} />;

export default PortalElement;
